import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import authorisation from './modules/authorisation';
import error from './modules/error';
import profile from './modules/profile';
import microservices from './modules/microservices';
import oauth from '@/store/modules/oauth.js';
import deviceSettings from '@/store/modules/deviceSettings.js';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['authorisation', 'oauth', 'deviceSettings'],
    }),
  ],
  modules: {
    authorisation,
    error,
    profile,
    microservices,
    oauth,
    deviceSettings
  },
});
